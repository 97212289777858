<template>
    <Suspense>
        <template #fallback>
            <div class="w-100 h-100">
                <Overlay />
            </div>
        </template>
        <template #default>
            <BaseGrid v-bind="$attrs" :data="dataObject.data" :dataLength="dataLength" :gridControl="gridControl" ref="baseGridRef"
                :dataTransformComposable="dataTransformComposable">
                <template #default>
                    <slot></slot>
                </template>
                <template #cardheader>
                    <slot name="cardheader"></slot>
                </template>

                <template #header>
                    <BaseHeader animatedContainer>
                        <template v-if="dataObject.hasNewRecords" #newrecords>
                            <Suspense>
                                <template #fallback>
                                    <Overlay />
                                </template>
                                <NewRecordsPanel/>
                            </Suspense>
                        </template>
                    </BaseHeader>
                </template>

                <template #systemcolumn>
                    <BaseColumn colId="o365_System" class="text-center" pinned="left" suppressMovable suppressSelection
                        disableResizable disableMenu width="30" maxWidth="30" suppressNavigable
                        style="user-select: 'none';">
                        <template #default="{ row }">
                            <i v-if="row.error" class="text-danger bi bi-exclamation-triangle-fill" :title="row.error"></i>
                            <div v-else-if="row.isSaving" class="spinner-border spinner-border-sm mt-1" role="status"></div>
                            <i v-else-if="row.isNewRecord" class="bi bi-star-fill" @click.stop="() => { }"></i>
                            <i v-else-if="row.hasChanges" class="bi bi-pencil-fill" style="cursor: pointer;" :title="$t('Save')"
                                @click.stop="() => { }"></i>
                            <i v-else-if="row.current" class="bi bi-caret-right-fill"></i>
                        </template>
                        <template #headertext>
                            <button v-if="dataObject.allowInsert && !dataObject.hasNewRecords" class="btn btn-link btn-sm p-0" @click.stop="gridControl.enableBatchRecords"
                                :title="$t('Add new row')">
                                <i class="bi bi-plus-square"></i>
                            </button>
                            <button v-else-if="dataObject.hasNewRecords" class="btn btn-link btn-sm p-0" @click.stop="gridControl.closeBatchRecords"
                                :title="$t('Close new records panel')">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </template>
                        <template #filter>
                            <div class="w-100 text-center"><i class="bi bi-search"></i></div>
                        </template>
                    </BaseColumn>
                    <BaseColumn colId="o365_MultiSelect" class="o365-selection-cell pt-1" pinned="left" suppressMovable suppressSelection
                        disableResizable disableMenu width="32" maxWidth="32" suppressNavigable disableDefaultClass
                        style="user-select: 'none'; text-align: center;">
                        <template #default="{ row }">
                            <input v-if="!row.item?.o_groupHeaderRow" type="checkbox" class="form-check-input p-2" v-model="row.isSelected" >
                        </template>
                        <template #headertext>
                            <input type="checkbox" class="form-check-input p-2" @click="gridControl.selectAll()" v-model="dataObject.selectionControl.allRowsSelected">
                        </template>
                        <template #filter>
                            <div class="w-100 text-center">
                                <a class="btn-link" 
                                    v-if="isRowSelected"
                                    @click="copySelected"
                                    title="Copy selected rows as plain Text. To copy as JSON press and hold Ctrl."
                                    href="#">
                                    <i :class="[copied ? 'bi-clipboard2-check' : 'bi-clipboard2', 'bi']"></i>
                                </a>
                            </div>
                        </template>
                    </BaseColumn>
                </template>

                <template #actioncolumn>
                    <BaseColumn colId="o365_Action" suppressMovable suppressSelection
                        disableResizable disableMenu width="28" maxWidth="28" suppressNavigable
                        style="user-select: 'none'; padding: 2px 0px 0px; text-align: center;">
                        <template #default="{ row }">
                            <ActionDelete v-if="(row.current && !row.isDirty && !row.isError) || row.isDeleting"
                                :confirm="props.disableDeleteConfirm" :softDelete="props.softDelete" :dataObject="props.dataObject" :row="row" :style="{ 'display': row.current && !row.hasChanges && !row.error ? '' : 'none' }">
                                <i class='bi bi-x-lg'></i>
                            </ActionDelete>
                            <ActionCancel v-else-if="row.current && (row.isDirty || row.hasChanges) && !row.isError"
                                :dataObject="props.dataObject" :row="row" :style="{ 'display': row.current && row.hasChanges ? '' : 'none' }">
                                <i class='bi bi-arrow-counterclockwise'></i>
                            </ActionCancel>
                        </template>
                        <template #headertext></template>
                        <template #filter></template>
                    </BaseColumn>
                </template>

                <template #statusbar>
                    <slot name="statusbar">
                        <InfoItems :dataObject="dataObject"/>
                    </slot>
                </template>

                <template #gridbodymenutabs>
                    <DataGridMenuTabs v-if="!hideGridMenu" />
                </template>

                <template #gridsidemenu>
                    <OGridMenu v-if="!hideGridMenu" 
                        :gridRef="gridControl" 
                        :containerHasHeader="$slots.cardheader !== undefined" 
                        ref="gridMenu" 
                        :initialVisible="!collapseGridMenu" 
                        :tabs="menuTabs" 
                        :iframeSrc="detailIframe" 
                        :initialWidth="initialMenulWidth" 
                        :groupByFolders="groupByFolders" 
                        :detailTabTitle="detailTabTitle">
                        <template v-for="tab in menuTabs" v-slot:[`tab(${tab.id})`]>
                            <!-- @slot 
                                @ignore -->
                            <slot :name="`menu-tab(${tab.id})`"></slot>
                        </template>
                    </OGridMenu>
                </template>

                <template #overlay>
                    <Overlay v-if="dataObject.state.isLoading"/>
                </template>

                <template #bodybottom>
                    <div v-if="props.dataObject.state.isNextPageLoading" class="d-flex w-100 position-absolute bg-body" :style="{bottom: gridControl.showWidthScrollbar ? '17px' : '0px'}">
                        <div class="progress w-100" style="height: 8px;">
                            <div class="progress-bar indeterminate-progress" role="progressbar"></div>
                        </div>
                    </div>
                </template>

            </BaseGrid>
        </template>
    </Suspense>
</template>

<script lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';
import type { Ref } from 'vue';

export interface IDataGridProps {
    dataObject: DataObject;
    /** Disable dynamic loading functionalities */
    disableDynamicLoading?: boolean,
    /** Disable delete confirm on the ActionDelete in grid */
    disableDeleteConfirm?: boolean,
    /** Overrides function called by grid for creating new records */
    createNewOverrideFn?: Function,
    /** Use soft delete for ActionDelete in grid */
    softDelete?: boolean,

    // Menu props
    /** When set to `true` will not render grid menu */
    hideGridMenu?: boolean,
    /** When set to `true` will render collapse the grid menu by default */
    collapseGridMenu?: boolean,
    /** The label used on the detail iframe tab */
    detailTabTitle?: string,
    /** The initial width on the sidepanel menu */
    initialMenulWidth?: number,
    /** When set to `true` will use group by folders */
    groupByFolders?: boolean,
   /**
    * The url to be used in the details iframe tab
    * @example `${site.oldGenUrl}/workflow-item?ID=${dsItems.current.ID}&HideNav=true`
    */
    detailIframe?: string,
    /**
    * An array of custom tab definitions for the grid sidemenu details tab
    * @example [
    *   { title: 'Custom Tab', id: 'tab1', iconClass: 'bi bi-1-square-fill', component: MyTabComponent}
    * ]
    */
    menuTabs?: {title?: string, id: string, iconClass?: string, component?: any}[],

    // Duplicated props, in vue 3.3 it is possible import types from other files/modules in the compiler
    // Need to investigate if it would be possible to do custom resolve based on our import maps
    // TODO: Remove after extarnal type imports are supported in our sfc compiler
    rowHeight?: number,
};
</script>

<script setup lang="ts">
import BaseGrid from 'o365.vue.components.Grid.BaseGrid.vue';
import BaseColumn from 'o365.vue.components.Grid.BaseColumn.vue';
import Overlay from 'o365.vue.components.Overlay.vue';
import useVirtualScroll from 'o365.vue.composables.VirtualScroll.js';
import useGridErrorHandler from 'o365.composables.Grid.ErrorHandler.ts';

import OGridMenu from 'o365.vue.components.DataGrid.GridMenu.vue';
import DataGridMenuTabs from 'o365.vue.components.DataGrid.GridMenuTabs.vue';


import DataGridControl from 'o365.controls.Grid.DataGrid.ts';

// Old grid temp provide
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';

import { Suspense, computed, ref, useAttrs, defineAsyncComponent, provide } from 'vue';

const ActionDelete = defineAsyncComponent(() => import('o365.vue.components.Action.Delete.vue'));
const ActionCancel = defineAsyncComponent(() => import('o365.vue.components.Action.Cancel.vue'));
const InfoItems = defineAsyncComponent(() => import('o365.vue.components.InfoItems.vue'));
const BaseHeader = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseHeader.vue'));
const NewRecordsPanel = defineAsyncComponent(() => import('o365.vue.components.Grid.NewRecordsPanel.vue'));

const props = withDefaults(defineProps<IDataGridProps>(), {
    rowHeight: 34,
});
const attrs = useAttrs();

if (!props.disableDynamicLoading) {
    if (props.dataObject.recordSource.maxRecords !== -1) {
        props.dataObject.enableDynamicLoading();
    }
}

useGridErrorHandler();

function dataTransformComposable(dataRef: Ref<any[]>) {
    const { scrollData, handleScroll, updateData: updateVirtualScrollData } = useVirtualScroll({
        dataRef: props.dataObject.paging?.data ?? props.dataObject.data,
        itemSize: 34, // props.rowHeight
        itemsToRender: 50, // props.itemsToRender,
        elementRef: computed(() => gridControl.value.viewport) , // viewportRef,
        recycleList: true,
        //dynamicLoading: (props.groupBy || props.treeColumnDefinition) ? null : props.dataObject.dynamicLoading,
        dynamicLoading: props.dataObject.dynamicLoading,
        // watchRef: props.treeColumnDefinition ? computed(() => props.dataObject.treeify?.updated) : props.groupBy ? computed(() => props.dataObject.groupBy?.updated) : null,
        // dataGridControl: props.debugTest ? dataGridControl : null
    });
    gridControl.value.viewportScrollHandler = handleScroll;
    return scrollData;
};

const dataLength = computed(() => {
    return props.dataObject.filterObject.appliedFilterString ? props.dataObject.filteredRowCount : props.dataObject.rowCount;
});

const gridControl = ref(new DataGridControl({
    id: attrs.id ?? `${props.dataObject.id}_grid`,
    props: props,
}));
provide(dataGridControlKey, gridControl);

//--- Multiselect copty ---
// TODO: Move out of main grid control into header
const isRowSelected = computed(() => {
    return props.dataObject?.data?.filter((item) => item?.isSelected).length > 0
});
const copied = ref(false);
function copySelected(e) {
    props.dataObject.selectionControl.copySelection(false, gridControl.value.columns.columns, e.ctrlKey)
    copied.value = !copied.value;
}

/** 
* Use gridControl.columns instead
* @deprecated 
*/
const dataColumns = computed(() => gridControl.value.dataColumns);

defineExpose({ gridControl, dataColumns });

</script>